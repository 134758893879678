import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '..'
import { ElapsedMillisecond } from '../../../../utils/dateUtil'
import { provisionCertificateFeatureStatus, yesNo } from '../../constant/classification'
import { PROVISION_CERTIFICATE_USER_REISSUE_STATUS } from '../../constant/commonConstant'

/**
 * システム全体の制御情報
 * 市民機能で必要な情報があれば追加する
 */
export interface SystemControl {
  serviceTitle: string
  /** ユーザー向けサービスURL */
  serviceUrlForUser: string
  /** 管理者向けサービスURL */
  serviceUrlForManager: string
  iconImageName: string | null
  s3BucketNameIcon: string
  projectSearchFlag: string
  facilityCategorySearchFlag: string
  systemDatetime?: ElapsedMillisecond
  /** 利用規約 */
  termsOfService: string
  /** S3バケット(操作マニュアル) */
  s3BucketNameManual: string
  /** S3バケット(利用規約) */
  s3BucketNameTermsOfUse: string
  /** S3バケット(医師連絡票)*/
  s3BucketNameCitizenUserReservationFile: string

  useCouponFunctionFlag: string
  /** クーポン利用できる期限（開始日） */
  usageCouponFromDate?: ElapsedMillisecond
  /** クーポン利用できる期限（終了日） */
  usageCouponToDate?: ElapsedMillisecond
  /** アカウント退会受付可否フラグ */
  unsubscribeAcceptFlag: string
  /** お子さま利用終了受付可否フラグ */
  childUseEndAcceptFlag: string
  /** SSO利用フラグ */
  ssoUseFlag: string,
  /** SSOリンク先名称 */
  ssoUseName: string | null,
  /** SSO連携時メール認証要否 */
  ssoEmailCertificationFlag: string | null,
  /** SMS契約有無フラグ */
  smsSubscriptionFlag: string,
  /** 電話番号の認証要否 */
  phoneCertificationFlag: string,
  /** 面談申込受付方法 */
  interviewDailyAcceptFlag: string,
  /** 面談申込時のお子さま複数申込可否 */
  interviewBulkReceptionFlag: string,
  /** 利用予約の重複登録フラグ */
  duplicateReservationControlFlag: string,
  /** 事業を跨いだ重複判定フラグ */
  crossBusinessDuplicationCheckFlag: string,
  /** 複数お子さま登録可否フラグ */
  multiChildReservationAvailabilityFlag: string,
  /** 利用者の退会時説明文 */
  descriptionForUnsubscribe: string,
  /** お子さまの利用終了時説明文 */
  descriptionForChildUseEnd: string,
  /** 提供証明書機能の利用有無 */
  provisionCertificateFeatureStatus: string,
  /** 徴収ステータス利用有無フラグ */
  paymentStatusFlag: string,
  /** （提供証明）徴収ステータス利用有無フラグ */
  provisionCertificatePaymentStatusFlag: string,
  provisionCertificateUserReissueStatus: PROVISION_CERTIFICATE_USER_REISSUE_STATUS
}

const defaultSystemControl: SystemControl = {
  serviceTitle: '',
  serviceUrlForUser: '',
  serviceUrlForManager: '',
  iconImageName: '',
  termsOfService: '',
  s3BucketNameIcon: '',
  projectSearchFlag: yesNo.no,
  facilityCategorySearchFlag: yesNo.no,
  s3BucketNameManual: '',
  s3BucketNameTermsOfUse: '',
  s3BucketNameCitizenUserReservationFile: '',
  useCouponFunctionFlag: yesNo.no,
  unsubscribeAcceptFlag: yesNo.no,
  childUseEndAcceptFlag: yesNo.no,
  ssoUseFlag: yesNo.no,
  ssoUseName: null,
  ssoEmailCertificationFlag: null,
  smsSubscriptionFlag: yesNo.no,
  phoneCertificationFlag: yesNo.no,
  interviewDailyAcceptFlag: yesNo.no,
  interviewBulkReceptionFlag: yesNo.no,
  duplicateReservationControlFlag: yesNo.no,
  crossBusinessDuplicationCheckFlag: yesNo.no,
  multiChildReservationAvailabilityFlag: yesNo.no,
  descriptionForUnsubscribe: '',
  descriptionForChildUseEnd: '',
  provisionCertificateFeatureStatus: provisionCertificateFeatureStatus.notUsed,
  paymentStatusFlag: yesNo.no,
  provisionCertificatePaymentStatusFlag: yesNo.no,
  provisionCertificateUserReissueStatus: PROVISION_CERTIFICATE_USER_REISSUE_STATUS.REISSUE_DISABLED
}

interface SystemControlState {
  systemControl?: SystemControl
}

const initialState: SystemControlState = {}

const sliceName = 'systemControl'

export const systemControlSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setSystemControl: (state, action: PayloadAction<SystemControl | undefined>) => {
      state.systemControl = action.payload
    },
  },
})

export const { setSystemControl } = systemControlSlice.actions
export const selectSystemControl = (state: RootState) => state.systemControl.systemControl ?? defaultSystemControl
export default systemControlSlice.reducer
