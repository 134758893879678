/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_mobile_analytics_app_id": "19ca08d121d643a395f0a6e9ff0f3d35",
    "aws_mobile_analytics_app_region": "ap-northeast-1",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "19ca08d121d643a395f0a6e9ff0f3d35",
            "region": "ap-northeast-1"
        }
    },
    "aws_cloud_logic_custom": [
        {
            "name": "citizen",
            "endpoint": "https://kf99hvonob.execute-api.ap-northeast-1.amazonaws.com/ktnb",
            "region": "ap-northeast-1"
        },
        {
            "name": "public",
            "endpoint": "https://26rkq4jpki.execute-api.ap-northeast-1.amazonaws.com/ktnb",
            "region": "ap-northeast-1"
        }
    ],
    "aws_cognito_identity_pool_id": "ap-northeast-1:389f2614-f103-458f-8b75-0fad5a581c49",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_M8Ii1Drmf",
    "aws_user_pools_web_client_id": "fr2d9i9dbksrgk61lut1s6bfr",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "PHONE_NUMBER"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": "6",
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
