import { SelectChangeEvent } from '@mui/material'
import { addMonths, startOfMonth, subMonths } from 'date-fns'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { executeGetUsageRecords } from '../../dataAccess/webApi/dao/usageRecordsDao'
import { formatHyphenYmd, fromApiYmd, fromApiYmdHms, getNow, toApiYm } from '../../utils/dateUtil'
import { nullPropsToUndefined, undefinedPropsToNull } from '../../utils/objectUtil'
import { formatLocaleTimeAmountHm } from '../../utils/timeUtil'
import { ChildrenChoice, getChildrenChoices } from '../common/child'
import { usageHistoryStatusSearchCondition, yesNo } from '../common/constant/classification'
import { OperationId } from '../common/constant/operationLog'
import { useErrorHandle } from '../common/error/errorHandler'
import { useOperationLog } from '../common/operationLog'
import { selectSystemControl } from '../common/store/slices/systemControl'
import { showLoading } from '../common/store/slices/application'
import { useProvisionCertificateIssuance } from '../provisionCertificateIssuance/useProvisionCertificateIssuance'
import { PROVISION_CERTIFICATE_USER_REISSUE_STATUS } from '../common/constant/commonConstant'
import { groupBy } from '../../utils/arrayUtil'

interface LocationState {
  /** アクティブな年月の値 */
  yyyymm?: Date
  /** アクティブなお子さまID */
  childId?: string
  /** アクティブな利用予約の検索条件 */
  statusSearchCondition?: string
}

interface UsageHistory {
  reservationNo: string
  usageDate: Date
  useFromDatetime: Date
  useToDatetime: Date
  facilityId: string
  facilityName: string
  certificateFlag: string
  issueCertificateFlag: string
  childName: string
  status: string
  usageMinute?: string
  paymentStatus: string
}

export interface PageState {
  activeYyyymm: Date
  activeChildId?: string
  activeStatusSearchCondition: string
  usageHistories?: UsageHistory[]
  childs: ChildrenChoice[]
  shouldShowDownloadCertificate: boolean
}

export type Load = (
  isInitialize: boolean,
  activeYyyymm: Date,
  loadedChilds: { value: string; label: string }[],
  activeStatusSearchCondition: string,
  activeChildId?: string
) => void

export const useAction = () => {
  const errorHandle = useErrorHandle()
  const dispatch = useDispatch()
  const { addOperationLog } = useOperationLog()
  const history = useHistory<LocationState | undefined>()
  const sysCtrl = useSelector(selectSystemControl)

  const locationState = history.location.state

  const [state, setState] = useState<PageState>({
    activeYyyymm: locationState?.yyyymm ?? startOfMonth(getNow()),
    activeChildId: locationState?.childId,
    activeStatusSearchCondition: locationState?.statusSearchCondition ?? usageHistoryStatusSearchCondition.usedOnly,
    childs: [],
    shouldShowDownloadCertificate: false,
  })

  const load = useCallback<Load>(
    (isInitialize, activeYyyymm, loadedChilds, activeStatusSearchCondition, activeChildId) => {
      dispatch(
        showLoading({
          process: errorHandle(async () => {
            const [childs, usageHistories] = await Promise.all([
              isInitialize ? getChildrenChoices() : loadedChilds,
              getUsageHistories(activeYyyymm, activeStatusSearchCondition, activeChildId),
            ])

            const usageHistoriesGroupByFacilityId = groupBy(
              usageHistories.filter((usageHistory) => usageHistory.certificateFlag === yesNo.yes),
              'facilityId'
            )
            const shouldShowDownloadCertificateResult: boolean[] = []
            Object.keys(usageHistoriesGroupByFacilityId).forEach((facilityId) => {
              const targetUsageHistories = usageHistoriesGroupByFacilityId[facilityId]

              shouldShowDownloadCertificateResult.push(
                sysCtrl.provisionCertificateUserReissueStatus ===
                  PROVISION_CERTIFICATE_USER_REISSUE_STATUS.DIFFERENCE_ALLOWED
                  ? targetUsageHistories.some((usageHistory) => usageHistory.issueCertificateFlag === yesNo.yes)
                  : targetUsageHistories.every((usageHistory) => usageHistory.issueCertificateFlag === yesNo.yes)
              )
            })

            setState({
              activeChildId,
              activeYyyymm,
              usageHistories,
              childs,
              activeStatusSearchCondition,
              shouldShowDownloadCertificate:
                usageHistories
                  .filter(
                    (usageHistory) =>
                      usageHistory.certificateFlag === yesNo.yes && usageHistory.issueCertificateFlag === yesNo.yes
                  )
                  .map((usageHistory) => usageHistory.facilityId).length > 0 &&
                shouldShowDownloadCertificateResult.some((result) => result),
            })
          }),
          isHiddenMain: isInitialize,
        })
      )
    },
    [sysCtrl]
  )

  const {
    provisionCertificateIssuanceShowFlag,
    onClickIssueProvisionCertificateIssuance,
    provisionCertificateIssuanceHandler,
    cancelHandler,
    certificateResultCode,
    certificateResultResponseData,
    resetCertificateResultResponse,
  } = useProvisionCertificateIssuance({
    childId: state.activeChildId ?? '',
    usageFromDate: formatHyphenYmd(state.activeYyyymm),
    facilityIds:
      Array.from(
        new Set(
          state.usageHistories
            ?.filter(
              (usageHistory) =>
                usageHistory.certificateFlag === yesNo.yes && usageHistory.issueCertificateFlag === yesNo.yes
            )
            .map((usageHistory) => usageHistory.facilityId)
        )
      ) ?? [],
    state,
    load,
  })

  useEffect(() => {
    addOperationLog({ operationId: OperationId.OP_00000001 })

    load(true, state.activeYyyymm, state.childs, state.activeStatusSearchCondition, state.activeChildId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // 戻るで表示した際にアクティブな選択を復元する為に履歴に保管
    history.replace({
      ...history.location,
      state: {
        yyyymm: state.activeYyyymm,
        childId: state.activeChildId,
        statusSearchCondition: state.activeStatusSearchCondition,
      },
    })
  }, [state.activeChildId, state.activeYyyymm, state.activeStatusSearchCondition])

  useEffect(() => {
    resetCertificateResultResponse()
  }, [state, resetCertificateResultResponse])

  const changeChild = useCallback(
    (event: SelectChangeEvent<string>) => {
      addOperationLog({ operationId: OperationId.OP_00000028 })

      load(false, state.activeYyyymm, state.childs, state.activeStatusSearchCondition, event.target.value)
    },
    [state.activeYyyymm, state.childs, state.activeStatusSearchCondition, addOperationLog]
  )

  const changeMonth = useCallback(
    (value: Date) => {
      load(false, value, state.childs, state.activeStatusSearchCondition, state.activeChildId)
    },
    [state]
  )

  const previousMonth = useCallback(() => {
    changeMonth(subMonths(state.activeYyyymm, 1))
  }, [state])

  const nextMonth = useCallback(() => {
    changeMonth(addMonths(state.activeYyyymm, 1))
  }, [state])

  const statusSearchConditionChangeHandle = useCallback(
    (event: SelectChangeEvent<string>) => {
      load(false, state.activeYyyymm, state.childs, event.target.value, state.activeChildId)
    },
    [state]
  )

  // 選択している月が過去月なら true を返す関数
  const dateChecker = useCallback(() => {
    const now = getNow()
    const startOfCurrentMonth = new Date(now.getFullYear(), now.getMonth())
    return state.activeYyyymm < startOfCurrentMonth
  }, [state.activeYyyymm])

  return {
    ...state,
    changeChild,
    previousMonth,
    nextMonth,
    statusSearchConditionChangeHandle,
    provisionCertificateIssuanceShowFlag,
    onClickIssueProvisionCertificateIssuance,
    provisionCertificateIssuanceHandler,
    cancelHandler,
    certificateResultCode,
    certificateResultResponseData,
    provisionCertificateFeatureStatus: sysCtrl.provisionCertificateFeatureStatus,
    paymentStatusFlag: sysCtrl.paymentStatusFlag,
    provisionCertificatePaymentStatusFlag: sysCtrl.provisionCertificatePaymentStatusFlag,
    dateChecker,
  }
}

const getUsageHistories = async (yyyymm: Date, activeStatusSearchCondition: string, childId?: string) => {
  const response = await executeGetUsageRecords({
    ...undefinedPropsToNull({ yyyymm: toApiYm(yyyymm), childId }),
    ...(activeStatusSearchCondition === usageHistoryStatusSearchCondition.usedOnly && { usedOnlyGetFlag: yesNo.yes }),
  })

  return response.result.map((usage) => {
    const { usageDate, useFromDatetime, useToDatetime, usageMinute, ...other } = usage
    return nullPropsToUndefined({
      ...other,
      usageDate: fromApiYmd(usageDate),
      useFromDatetime: fromApiYmdHms(useFromDatetime),
      useToDatetime: fromApiYmdHms(useToDatetime),
      usageMinute: formatLocaleTimeAmountHm(usageMinute),
    })
  })
}
