import { executePost, executePut } from '../common/apiCaller'
import {
  PostCreatePdfDto,
  PostCreatePdfInputDto,
  PostDownloadPdfUrlDto,
  PostDownloadPdfUrlInputDto,
  PutCertificateDetailInputDto,
} from '../dto/certificateDto'

const apiName = 'public'
const apiPath = '/certificate'

export const executePostCreateCertificatePdf = (input: PostCreatePdfInputDto) => {
  return executePost<PostCreatePdfDto>(apiName, `${apiPath}/create`, input)
}
export const executePostDownloadCertificatePdfUrl = (input: PostDownloadPdfUrlInputDto) => {
  return executePost<PostDownloadPdfUrlDto>(apiName, `${apiPath}/pdf`, input)
}
export const executePutCertificateDetail = (input: PutCertificateDetailInputDto) => {
  return executePut<null>(apiName, `${apiPath}/detail`, input)
}
